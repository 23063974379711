import React, { useEffect } from "react"
import { Box, Container, Flex, Styled, useColorMode } from "theme-ui"
import { colorModes } from "@const"

import Layout from "@layouts/default"
import SEO from "@components/seo"

import ContactForm from "@forms/ContactForm"
import ContactData from "@components/ContactData"

import Bubble from "@assets/speechBubble.svg"

export default () => {
  const [colorMode, setColorMode] = useColorMode()
  useEffect(() => setColorMode("light"), [colorMode, setColorMode])

  return (
    <Layout colorMode={colorModes.dark}>
      <SEO title="Home" />
      <Flex as="section" variant="layout.contact.container">
        <Container variant="layout.flexColumn">
          <Flex variant="layout.contact.header">
            <Flex>
              <Box variant="layout.contact.bubble">
                <Bubble />
              </Box>
              <Box>
                <Styled.h1>Masz pytanie?</Styled.h1>
                <Styled.p>
                  Napisz do nas a my odpiszemy jak najszybciej.
                </Styled.p>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Flex>

      <Container as="section">
        <Flex variant="layout.contact.content">
          <ContactForm />
          <ContactData />
        </Flex>
      </Container>
    </Layout>
  )
}
