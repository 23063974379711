import React from "react"
import { Box, Flex, Styled } from "theme-ui"
import SocialMediaButtons from "@components/SocialMediaButtons"

import EmailIcon from "@assets/email.svg"
import PhoneIcon from "@assets/phone.svg"
import PlaceIcon from "@assets/place.svg"

export default () => (
  <Flex variant="layout.contact.data">
    <Styled.h4>Dane kontaktowe</Styled.h4>
    <Styled.h5>dworld.pl</Styled.h5>
    <Flex>
      <EmailIcon />
      <Styled.p>hello@dworld.pl</Styled.p>
    </Flex>
    <Flex>
      <EmailIcon />
      <Styled.p>dworld.pl</Styled.p>
    </Flex>
    <Flex>
      <PhoneIcon />
      <Styled.p>+1 131 138 138</Styled.p>
    </Flex>
    <Flex>
      <PlaceIcon />
      <Box>
        <Styled.p>ul. Krakowska 11, 30-316</Styled.p>
        <Styled.p>Kraków, Polska</Styled.p>
      </Box>
    </Flex>

    <Flex
      sx={{
        flexWrap: `wrap`,
      }}
    >
      <Flex variant="layout.contact.data.socialButtons">Social media:</Flex>
      <SocialMediaButtons />
    </Flex>
  </Flex>
)
