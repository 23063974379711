import React from "react"
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Label,
  Input,
  Styled,
  Textarea,
} from "theme-ui"

export default () => (
  <Flex variant="layout.contact.form">
    <form>
      <Styled.h4>Napisz do nas</Styled.h4>
      <Input
        type="email"
        placeholder="E-mail"
        sx={{
          height: `68px`,
          border: `1px solid #D8D8D8`,
          borderRadius: `40px`,
          mb: `16px`,
          mt: '50px'
        }}
      />
      <Textarea
        name="comment"
        rows="10"
        mb={3}
        placeholder="Twoja wiadomość..."
      />
      <Box>
        <Label mb={3} sx={{ mt: `30px`, mb: '0'}}>
          <Checkbox />
          Akceptuję warunki Polityki Prywatności
        </Label>
      </Box>
      <Button
        sx={{
          color: "black",
          fontFamily: "body",
          fontWeight: "600",
          fontSize: "15px",
          width: `100%`,
          height: `68px`,
          background: `#FBB03B 0% 0% no-repeat padding-box`,
          borderRadius: `40px`,
          my: `30px`
        }}
      >
        Wyślij
      </Button>
    </form>
  </Flex>
)
